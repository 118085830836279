import { AbstractMesh, Mesh, Texture, Vector3 } from '@babylonjs/core';
import { Inspector } from '@babylonjs/inspector';
import { ReactNode, useRef, useState } from "react";
import { CSSProperties } from "styled-components";
import { GLTF2Export } from '@babylonjs/serializers/glTF';
import './App.css';
import { export_attachment_offset } from "./attachment_offset";
import { CheckIcon, ChoicesIcon, DefaultBG, EditColors, EditMaterials, IconButton, OptionsIcon, SelectColor, StickyHeader, Tab, TreeMenu } from './components';
import context from "./context";
import { color_options, decal, decal_edit_state, decal_manage_state, default_decal_edit_state, default_decal_manage_state, default_model_paint_state, eball_clasp, ebite, ecover, edecal, ematerial, emodel, epaint, esplint, ethickness, eupdown, is_3dprint, is_dev, lettering_color_options, model_paint_state, sticker_names, sticker_path } from "./state";
import stickers from './stickers.json';
import axios from 'axios';

export enum emenu{
    dracular_color,

    sportsguard_stripe,
    sportsguard_chequered,
    sportsguard_mixed,
    sportsguard_cover,
    sportsguard_dracular,
    sticker,
    lettering,
    user_image,
    thickness,
    bite,

    splint_type,
    splint_updown,
    splint_color,    
    splint_attachments,
    splint_material,
}

async function accept_user_image(input: HTMLInputElement){
    return await new Promise<{filename: string, arraybuffer: string}>(res => {
        input.onchange = (ev) => {
            // you can use this method to get file and perform respective operations
            if(input.files && input.files!.length){
                let file = input.files.item(0)!;
                let reader = new FileReader();
                reader.onloadend = (e) => {
                    let arraybuffer = e.target?.result as string;
                    res({filename: file.name, arraybuffer});
                }
                reader.readAsDataURL(file);
            }
        };
        input.click();
    });
}

function Overlay(prop: {loading: boolean, uploading: (status: boolean) => void, change_env: () => void}){
    // console.log('overlay rendered');
    const [inspector_visible, set_inspector_visible] = useState(false);
    const [model_paint_state, set_model_paint_state] = useState(default_model_paint_state);
    const [decal_edit_state, set_decal_edit_state] = useState(default_decal_edit_state);
    const [decal_manage_state, set_decal_manage_state] = useState(default_decal_manage_state);
    const [user_image_files, set_user_image_files] = useState({} as {[k: string]: string});
    const [decal_version, set_decal_version] = useState(0);
    const [sub_menu_bar_class, set_sub_menu_bar_class] = useState('sub-menu-bar-hide');
    const [main_menu_bar_class, set_main_menu_bar_class] = useState('main-menu-bar-show');
    const [option_visible, set_option_visible] = useState(false);

    const [menu, set_menu] = useState(emenu.thickness);

    const file_input = useRef<HTMLInputElement>(null);

    const scene = context.scene;

    const apiUrl = 'http://15.223.9.146:9091/';

    const update_model_paint = (patch: Partial<model_paint_state>) => {
        const p = {
            ...model_paint_state,
            ...patch,
        };

        // updown available only on sprint
        const splint_updown_restriction: {[k in esplint]: eupdown} = {
            [esplint.splint]: p.updown,
            [esplint.ferrar]: eupdown.upper,
            [esplint.gelb]: eupdown.lower,
            [esplint.gelb_split]: eupdown.lower,
            [esplint.NTI]: eupdown.upper,
            [esplint.kois]: eupdown.upper,
        };

        p.updown = splint_updown_restriction[p.splint_type];

        // cover availability
        if(p.sportsguard_dracular){
            p.cover = ecover.none;
        }

        

        // NOTE: splint prop priority
        // - material
        // - type
        // - color
        // - attachments
        
        if(is_3dprint(p.splint_material) && p.splint_type === esplint.gelb_split){
            p.splint_type = esplint.gelb;
        }

        // splint color availability
        p.splint_color = 
            p.splint_material === ematerial.blue3d ? color_options.blue.mrc4 :
            p.splint_material === ematerial.clear3d ? color_options.clear.mrc4 :
            p.splint_color;

        // attachment availability
        if(is_3dprint(p.splint_material)){
            p.splint_lingual_bar = false;
            p.splint_ball_clasp = eball_clasp.none;
        }

        if(p.splint_type === esplint.NTI){
            p.splint_lingual_bar = false;
            p.splint_ball_clasp = eball_clasp.none;
        }

        if(p.splint_type === esplint.gelb_split){
            p.splint_lingual_bar = true;
        }



        set_model_paint_state(p);
    };

    const update_decal_edit = (patch: Partial<decal_edit_state>) => {
        set_decal_edit_state({
            ...decal_edit_state,
            ...patch,
        });
        // update_state();
    };

    const update_decal_manage = (patch: Partial<decal_manage_state>) => {
        set_decal_manage_state({
            ...decal_manage_state,
            ...patch,
        });
        // update_state();
    };

    const toggle_inspector = () => {
        if(!Inspector.IsVisible){
            Inspector.Show(scene, {
                overlay: true,
            });
        }
        else{
            Inspector.Hide();
        }
        
        set_inspector_visible(Inspector.IsVisible);        
	}

    const submit_design = () => {
        const filename: number = Date.now();

        scene.meshes.forEach((mesh) => {
            console.log(`Mesh: ${mesh.name}, Material: ${mesh.material?.name || "None"}`);
        });

        const custom_scene = scene;
        custom_scene.meshes.forEach((mesh) => {
            console.log(`Mesh: ${mesh.name}, Material: ${mesh.material?.name || "None"}, Visibility: ${mesh.visibility}`);
            if (mesh.name.indexOf("decal-preview") > -1) {
                if (mesh.material != null) {
                    mesh.material!.alpha = 0;
                }
            }
        });

        GLTF2Export.GLBAsync(custom_scene, "" + filename, {
            shouldExportNode: (node) => {
                const mesh = node as Mesh;
                // 특정 노드를 제외하고 내보내기
                if (model_paint_state.model === emodel.sportsguard) {
                    return (mesh.isVisible) && (node.name !== "skybox") && (node.name.indexOf("long-cover") < 0) && (node.name.indexOf("short-cover") < 0);
                }   else {
                    return (mesh.isVisible) && (node.name !== "skybox") && (node.name.indexOf("decal-preview") < 0);
                }
            },
          }).then((glb) => {
            // download glb file for test - before build comment this
            // glb.downloadFiles();

            // upload scene to server
            let newBlob;
            for (const key in glb.glTFFiles) {
                const blob = glb.glTFFiles[key];
                let mimeType;
                if (key.endsWith(".glb")) {
                    mimeType = { type: "model/gltf-binary" };
                    newBlob = new Blob([blob], mimeType);
                }
            }
            
            if (newBlob) {
                let fileName = `${filename}.glb`;
                let file = new File([newBlob], fileName);
                upload_scene(file);
            }
        });
    }

    const upload_scene = async (file: File) => {
        const type = model_paint_state.model === emodel.sportsguard ? 'sportsguard' : 'splint';
        let formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('type', type);

        try {
            prop.uploading(true);
            await axios.post(
                `${apiUrl}app/dentist/submit_temp/design`,
                formData
                // { withCredentials: true }
            ).then((result) => {
                prop.uploading(false);
                let response = result.data;
                if (Number(response.result_code) === 0) {
                    // alert('submit success');
                    const id = response.result_data.id;
                    window.location.href = 'http://15.223.9.146/home/' + id;
                } else {
                    alert('submit failed');
                }
            });
        } catch (e) {
            alert('submit error');
            prop.uploading(false);
        }
    }
    
    const option = () => {
        return (
            <div
                style={{position: 'relative', margin: 'auto'}}
            >
                <IconButton 
                    key={'option'}
                    icon_name={'menu'}
                    invert={option_visible}
                    on_click={() => set_option_visible(!option_visible)}
                />
                {
                    option_visible && 
                    [
                        <div 
                            className='cover' 
                            key={'cover'}
                            style={{zIndex: 1}}
                            onClick={() => set_option_visible(false)} 
                        />,
                        <TreeMenu
                            key={'popup'}
                            desc={{
                                [model_paint_state.model === emodel.sportsguard ? 'splint' : 'sportsguard'] : () => {
                                    if(model_paint_state.model === emodel.sportsguard){
                                        update_model_paint({
                                            model: emodel.splint,
                                            splint_material: default_model_paint_state.splint_material,
                                            updown: default_model_paint_state.updown,
                                            splint_color: default_model_paint_state.splint_color,
                                        });                                        
                                    }
                                    else{
                                        update_model_paint({model: emodel.sportsguard});
                                    }   
                                    set_menu(emenu.thickness);                                 
                                    set_option_visible(false);
                                },
                                submit: () => {
                                    console.log('submit');
                                    set_option_visible(false);
                                    submit_design();
                                },                                
                            }}
                            depth={0}
                        />
                    ]
                }
            </div>
        );
    };
    
    const menu_icon = (
        icon: string, 
        m: emenu, 
        mp: Partial<model_paint_state> = {}, 
        de: Partial<decal_edit_state> = {decal: edecal.none},
        dm: Partial<decal_manage_state> = {decal: undefined},
    ) => {
        // 상단 메뉴 버튼 클래스 
        return <IconButton 
            key={icon}
            icon_name={icon}
            invert={menu === m}
            on_click={() => {
                //상단 메뉴 클릭할때. 
                console.log("click menu");
                console.log(m);
                console.log(dm);
                console.log(de);
                set_menu(m);                
                update_model_paint(mp);
                update_decal_edit(de);
                update_decal_manage(dm);
                set_main_menu_bar_class('main-menu-bar-hide');
                set_sub_menu_bar_class('sub-menu-bar-show');
            }}
        />                    
    };

    const change_bg_button = () => {
        return <IconButton 
            key='panorama'
            icon_name='panorama'
            invert={false}
            on_click={() => {prop.change_env()}}
        />
    };

    const submenu = (current: emenu, legend: string, children: ReactNode) => {
        return (
            menu === current &&
            <>
                <div
                    className="hide-on-landscape"
                >
                    <IconButton
                        icon_name='submenu'
                        invert={option_visible}
                        on_click={() => {
                            set_sub_menu_bar_class('sub-menu-bar-hide');
                            set_main_menu_bar_class('main-menu-bar-show');
                            update_decal_edit({decal: edecal.none});
                            update_decal_manage({decal: undefined});
                        }}
                    />
                </div>
                {children}
            </>
        );
    };

    context.notify_pick = () => {        
        // TODO: only visible decals(dracular / 3dprint hide decals)

        console.log("notify_pick");
        //클릭했는데, 편집중인 데칼이 없으면, 클릭한 것중에 관리를 해야한다. 
        if(decal_edit_state.decal === edecal.none){
            let results = context.decals.map(decal => {
                var pickResult = context.scene.pick(scene.pointerX, scene.pointerY, (m) => m.isVisible && m === decal.mesh);
                if(pickResult && pickResult.ray && pickResult.pickedMesh === decal.mesh){
                    let dot = Vector3.Dot(decal.normal, pickResult.ray.direction);
                    if(dot < 0){
                        return {
                            decal,
                            pickResult,
                            dot,
                        };
                    }
                }
                return null;
            });
            
            results = results.filter(dp_or_null => dp_or_null !== null);


            if(results.length && !decal_manage_state.decal){
                let decal = results.sort((x, y) => x!.dot - y!.dot)[0]?.decal;
                //여기서는 관리모드로 들어간다.
                // update_decal_manage({decal});
                //여기서 편집모드로 들어가도록 수정중 
                update_decal_edit({decal:edecal.edit, decal_info:decal, lettering_text: decal!.lettering_text, lettering_color: decal!.lettering_color});
            }
            else if(decal_manage_state.decal){
                //관리중이면, 관리를 해제한다. 
                update_decal_edit({decal: edecal.none});
                // update_decal_manage({decal: undefined});
            }
        }
    };

    context.notify_decal_updated = (continue_edit: boolean) => {
        //decal_version이 뭐하는걸까나.. 별로 쓰는 곳이 없는거 같은데 
        set_decal_version(decal_version + 1);

        if(!continue_edit){
            update_decal_edit({decal: edecal.none});
            update_decal_manage({decal: undefined});
        }
    };

    const replace_splint_type = (splint_type: esplint) => {
        let patch: Partial<model_paint_state> = {splint_type};
        if(model_paint_state.splint_type === esplint.gelb_split && splint_type !== esplint.gelb_split){
            patch = {
                ...patch,
                splint_lingual_bar: false,
            };
        }
        update_model_paint(patch);
    }

    const gelb_family = [esplint.gelb, esplint.gelb_split];
    const ferrar_family = [esplint.ferrar, esplint.kois, esplint.NTI];
    
    context.model_paint_state_quqeue.push(model_paint_state);
    context.decal_edit_state_queue.push(decal_edit_state);
    context.decal_manage_state_queue.push(decal_manage_state);
    
    if(decal_manage_state.decal && !get_decal_renderable(decal_manage_state.decal)){
        update_decal_manage({decal: undefined});
    }

    return (        
        <div>
        {   
            prop.loading &&
            <div
                className="spinner"
                key={'spinner'}
            >
                <div
                    className="spinner-wheel"
                />
            </div>
        }            
        {
            is_dev() &&
            <div 
                key={'debug-bar'}
                className='debug-bar'                                
            >                    
                <button 
                    key={'toggle-inspector'}
                    onClick={toggle_inspector}
                >
                    {`${inspector_visible ? 'hide': 'show'} inspector`}
                </button>
                {
                    !inspector_visible &&
                    <button 
                        key={'export-attachment-offsets'}
                        onClick={() => {
                            let prefix = context.model && (context.model as any)['prefix'];
                            let attachments = context.model && (context.model as any)['attachments'];
                            if(prefix && attachments){
                                export_attachment_offset(prefix, attachments);
                                const path = (context.model.constructor as any)['path']
                                alert(`attachment offsets copied : ${path}`);
                            }
                        }}
                    >
                        {`export attachment offsets`}
                    </button>
                }
            </div>
        }   
            {
                !inspector_visible &&
                <div
                    className={`main-menu-bar ${main_menu_bar_class}`}
                    key={'main-menu-bar'}
                >
                    {option()}    
                    <DefaultBG
                        icon_button_bar={true}
                    >               
                    {
                        model_paint_state.model === emodel.sportsguard && [                            
                            !model_paint_state.sportsguard_dracular && (menu_icon('calipers', emenu.thickness)),
                            !model_paint_state.sportsguard_dracular && (menu_icon('util_03', emenu.sportsguard_stripe, {paint: epaint.stripe})),
                            !model_paint_state.sportsguard_dracular && (menu_icon('util_01', emenu.lettering, {}, {decal: edecal.lettering})),
                            !model_paint_state.sportsguard_dracular && (menu_icon('util_05', emenu.sportsguard_chequered, {paint: epaint.chequered})),
                            !model_paint_state.sportsguard_dracular && (menu_icon('util_02', emenu.sticker)),
                            !model_paint_state.sportsguard_dracular && (menu_icon('util_04', emenu.sportsguard_mixed, {paint: epaint.mixed})),
                            !model_paint_state.sportsguard_dracular && (menu_icon('util_06', emenu.bite)),
                            !model_paint_state.sportsguard_dracular && (menu_icon('util_09', emenu.sportsguard_cover)),
                            !model_paint_state.sportsguard_dracular && (menu_icon('util_07', emenu.user_image)),
                            (menu_icon('util_08', emenu.sportsguard_dracular)),
                            change_bg_button(),
                        ]
                    }                    
                    {
                        model_paint_state.model === emodel.splint && [                            
                            model_paint_state.splint_type === esplint.splint && (menu_icon('updown_select', emenu.splint_updown, {model: emodel.splint})),
                            (menu_icon('material', emenu.splint_material,{splint_material:ematerial.clear3d })),
                            // [ematerial.thermo, ematerial.hard].includes(model_paint_state.splint_material) && (menu_icon('util_03', emenu.splint_color, {model: emodel.splint})),
                            (menu_icon('util_03', emenu.splint_color, {model: emodel.splint})),
                            // !is_3dprint(model_paint_state.splint_material) && (menu_icon('util_03', emenu.splint_color, {model: emodel.splint})),
                            (menu_icon('calipers', emenu.thickness)),
                            !is_3dprint(model_paint_state.splint_material) && (menu_icon('util_01', emenu.lettering, {}, {decal: edecal.lettering})),
                            !is_3dprint(model_paint_state.splint_material) && (menu_icon('util_02', emenu.sticker)),

                            (menu_icon(
                                // gelb_family.includes(MPS.splint_type) ? 'side_basic' :
                                gelb_family.includes(model_paint_state.splint_type) ? 'device2' :
                                // ferrar_family.includes(MPS.splint_type) ? 'point_01' : 
                                ferrar_family.includes(model_paint_state.splint_type) ? 'device3' : 
                                // 'point_05', 
                                'device1',
                                emenu.splint_type, {model: emodel.splint})),

                            (menu_icon('util_06', emenu.bite)),
                            !is_3dprint(model_paint_state.splint_material) && (menu_icon('util_07', emenu.user_image)),
                            !is_3dprint(model_paint_state.splint_material) && model_paint_state.splint_type !== esplint.NTI && (menu_icon('util_08', emenu.splint_attachments)),
                            change_bg_button(),
                        ]
                    }
                    </DefaultBG>
                </div>
            }
            {
                !inspector_visible &&
                <div 
                    className={`sub-menu-bar ${sub_menu_bar_class}`}
                    key={'sub-menu-bar'}
                >                
                {
                    submenu(
                        emenu.thickness, 
                        'select thickness',                    
                        ChoicesIcon(
                            model_paint_state.model === emodel.sportsguard 
                                ? {[ethickness.thin]: 'calipers_4', [ethickness.thick]: 'calipers_5'}
                                : {[ethickness.thin]: 'calipers_2', [ethickness.thick]: 'calipers_3'},
                            model_paint_state.thickness,
                            (thickness) => update_model_paint({thickness})
                        )
                    )
                }
                {
                    submenu(
                        emenu.sportsguard_stripe, 
                        'select colors for stripes',
                        <div
                            className="sub-sub-menu"
                        >
                        {
                            ChoicesIcon(
                                {
                                    1: '1color',
                                    2: '2color',
                                    3: '3color',
                                    4: '4color',
                                    5: '5color',
                                },
                                model_paint_state.stripe_colors.length,
                                (n) => {
                                    if(n < model_paint_state.stripe_colors.length){
                                        update_model_paint({
                                            stripe_colors: model_paint_state.stripe_colors.slice(0, n),
                                        })
                                    }
                                    else if(n > model_paint_state.stripe_colors.length){
                                        update_model_paint({
                                            stripe_colors: model_paint_state.stripe_colors.concat(new Array(n - model_paint_state.stripe_colors.length).fill(color_options.white.mrc4)),
                                        })
                                    }
                                }                            
                            )
                        }
                        {
                            EditColors(model_paint_state.stripe_colors, stripe_colors => update_model_paint({stripe_colors}))
                        }
                        </div>
                    )
                }
                {
                    submenu(
                        emenu.dracular_color, 
                        'select colors for dracular',
                        EditColors(model_paint_state.dracular_colors, dracular_colors => update_model_paint({dracular_colors}))                                
                    )
                }
                {
                    submenu(
                        emenu.sportsguard_chequered, 
                        'select colors for chequered',
                        EditColors(model_paint_state.chequered_colors, chequered_colors => update_model_paint({chequered_colors}))                    
                    )
                }
                {
                    submenu(
                        emenu.sportsguard_mixed, 
                        'select colors to mixed',
                        <div
                            className="sub-sub-menu"
                        >
                        {
                            ChoicesIcon(
                                {                                    
                                    2: '2color',
                                    3: '3color',
                                },
                                model_paint_state.mixed_colors.length,
                                (n) => {
                                    if(n < model_paint_state.mixed_colors.length){
                                        update_model_paint({
                                            mixed_colors: model_paint_state.mixed_colors.slice(0, n),
                                        })
                                    }
                                    else if(n > model_paint_state.mixed_colors.length){
                                        update_model_paint({
                                            mixed_colors: model_paint_state.mixed_colors.concat(new Array(n - model_paint_state.mixed_colors.length).fill(color_options.white.mrc4)),
                                        })
                                    }
                                }                            
                            )
                        }
                        {
                            EditColors(model_paint_state.mixed_colors, mixed_colors => update_model_paint({mixed_colors}))
                        }
                        </div>
                    )
                }            
                {
                    submenu(
                        emenu.sticker, 
                        'select sticker',
                        <Tab
                            tabs={Object.keys(stickers)}
                            selected={Object.keys(stickers).findIndex(c => decal_edit_state.sticker_category === c)}
                            on_changed={(n) => {
                                update_decal_edit({
                                    sticker_category: Object.keys(stickers)[n],
                                    sticker_name: sticker_names(Object.keys(stickers)[n])[0],
                                });                                
                                update_decal_manage({decal: undefined});
                            }}
                        >
                            <div key='container' className="grid" style={{'--size': '160px'} as CSSProperties}>
                            {
                                sticker_names(decal_edit_state.sticker_category).map(n => (
                                // sticker_names(decal_edit_state.sticker_category).slice(0, 10).map(n => (
                                    <button
                                        key={n} 
                                        className={decal_edit_state.decal === edecal.sticker && n === decal_edit_state.sticker_name ? 'thumbnail-button selected' : 'thumbnail-button'}
                                        onClick={() => {
                                            update_decal_edit({decal: edecal.sticker, sticker_name: n});
                                            update_decal_manage({decal: undefined});
                                        }}
                                    >
                                        <img 
                                            src={sticker_path(decal_edit_state.sticker_category, n)} 
                                            alt={sticker_path(decal_edit_state.sticker_category, n)}                                            
                                        />
                                    </button>

                                ))
                            }                                    
                            </div>                        
                        </Tab>
                    )
                }
                {
                    submenu(
                        emenu.user_image, 
                        'upload your image',
                        <StickyHeader
                            head={
                                [
                                    <IconButton 
                                        key='add new'
                                        icon_name='build'
                                        invert={false}
                                        on_click={() => accept_user_image(file_input.current!).then(file => {
                                            if(!user_image_files[file.filename]){
                                                set_user_image_files({
                                                    ...user_image_files, 
                                                    [file.filename]: file.arraybuffer
                                                });
                                            }
                                        })}
                                    />,
                                    decal_edit_state.decal === edecal.file && 
                                    Object.values(user_image_files).some(d => decal_edit_state.file_data === d) &&
                                    <IconButton 
                                        key='remove'
                                        icon_name='minus'
                                        invert={false}
                                        on_click={() => {
                                            const k = Object.keys(user_image_files).find(k => decal_edit_state.file_data === user_image_files[k]);
                                            if(k){
                                                delete user_image_files[k];
                                                update_decal_edit({decal: edecal.none});
                                            }
                                        }}
                                    />,
                                ]
                            }
                            body={
                                <div key='container' className="grid" style={{'--size': '50px'} as CSSProperties}>
                                {
                                    // Array(10).fill(Object.keys(user_image_files)[0]).map(filename => {
                                    Object.keys(user_image_files).map(filename => {
                                        let data = user_image_files[filename];
                                        return (
                                            <button 
                                                key={filename} 
                                                className={data === decal_edit_state.file_data ? 'thumbnail-button selected' : 'thumbnail-button'}
                                                onClick={() => update_decal_edit({decal: edecal.file, file_data: data})}
                                            >
                                                <img 
                                                    src={data} 
                                                    alt={filename}                                                
                                                />
                                            </button>
                                        );
                                    })
                                }
                                </div>
                            }
                        />                       
                    )
                }
                {
                    submenu(
                        emenu.lettering, 
                        'input sentences or caption',                                                
                        <DefaultBG>
                            <SelectColor                                                            
                                init_color={decal_edit_state.lettering_color}                                                                                            
                                on_change={(c) => {
                                    update_decal_edit({
                                        decal: edecal.lettering,
                                        lettering_color: c
                                    });
                                    update_decal_manage({
                                        decal: undefined,
                                    });
                                }}
                                color_options={lettering_color_options}
                            />
                            <input                                 
                                value={decal_edit_state.lettering_text} 
                                onChange={(e) => {
                                    update_decal_edit({
                                        decal: edecal.lettering,
                                        lettering_text: (e.target as any)['value']
                                    });
                                    update_decal_manage({
                                        decal: undefined,
                                    })
                                }}
                                style={{
                                    height: '40px', 
                                    borderRadius: '30px', 
                                    margin: 'auto', 
                                    textIndent: '10px',
                                    fontSize: '20px',
                                    width: '100%',
                                }}
                            />                                
                        </DefaultBG>,                                                    
                    )
                }                
                {
                    model_paint_state.model === emodel.sportsguard
                    ? submenu(
                        emenu.bite, 
                        'select bite',      
                        CheckIcon(
                            // 'point_05', 
                            'device1',
                            model_paint_state.bite === ebite.all, 
                            (b) => update_model_paint({bite: b ? ebite.all : ebite.none})
                        )
                    )
                    : model_paint_state.model === emodel.splint && model_paint_state.splint_type === esplint.splint
                    ? submenu(
                        emenu.bite, 
                        'select bite',                          
                        OptionsIcon(
                            // {[ebite.molar]: 'side_basic', [ebite.all]: 'point_05'},
                            {[ebite.molar]: 'device2', [ebite.all]: 'device1'},
                            ebite.none,
                            model_paint_state.bite,
                            (bite) => update_model_paint({bite})
                        )
                    )
                    : submenu(
                        emenu.bite, 
                        'select bite',                        
                        CheckIcon(
                            // 'point_05', 
                            'device1',
                            model_paint_state.bite === ebite.all, 
                            (b) => update_model_paint({bite: b ? ebite.all : ebite.none})
                        )
                    )
                }
                {
                    submenu(
                        emenu.sportsguard_cover, 
                        'select covered',                    
                        OptionsIcon(
                            // {[ecover.long]: 'point_05', [ecover.short]: 'point_01'},
                            {[ecover.long]: 'device1', [ecover.short]: 'device3'},
                            ecover.none,
                            model_paint_state.cover,
                            (cover) => update_model_paint({cover})
                        )
                    )
                }
                {
                    submenu(
                        emenu.sportsguard_dracular, 
                        'select dracular',
                        [
                            CheckIcon(
                                'dracula',
                                model_paint_state.sportsguard_dracular,
                                (sportsguard_dracular) => update_model_paint({sportsguard_dracular})
                            ),
                            model_paint_state.sportsguard_dracular && EditColors(model_paint_state.dracular_colors, dracular_colors => update_model_paint({dracular_colors}))
                        ]
                    )
                }
                {    
                    submenu(
                        emenu.splint_type, 
                        'select splint type',
                        [
                            <DefaultBG>
                                <IconButton 
                                    key={esplint.splint}
                                    icon_name='device1'
                                    invert={model_paint_state.splint_type === esplint.splint}
                                    on_click={() => replace_splint_type(esplint.splint)}
                                />
                            </DefaultBG>,
                            !gelb_family.includes(model_paint_state.splint_type)
                            ? <DefaultBG>
                                <IconButton
                                    key={esplint.gelb}
                                    icon_name='device2'
                                    on_click={() => replace_splint_type(esplint.gelb)}
                                />
                            </DefaultBG>
                            : ChoicesIcon(
                                is_3dprint(model_paint_state.splint_material) ? {[esplint.gelb]: 'block04'} : {[esplint.gelb]: 'block04', [esplint.gelb_split]: 'block05'},
                                model_paint_state.splint_type,
                                (splint_type) => replace_splint_type(splint_type)
                            ),

                            !ferrar_family.includes(model_paint_state.splint_type) 
                            ? <DefaultBG>
                                <IconButton
                                    key={esplint.ferrar}
                                    icon_name='device3'
                                    on_click={() => replace_splint_type(esplint.ferrar)}
                                />
                            </DefaultBG>
                            : ChoicesIcon(
                                {[esplint.ferrar]: 'block03', [esplint.kois]: 'block02', [esplint.NTI]: 'block01'},
                                model_paint_state.splint_type,
                                (splint_type) => replace_splint_type(splint_type)
                            ),
                        ]
                    )
                }
                {    
                    submenu(
                        emenu.splint_updown, 
                        'select splint up/down/both',
                        ChoicesIcon(
                            {
                                [eupdown.upper]: 'up',
                                [eupdown.lower]: 'down',
                                // [eupdown.both]: 'updown',
                            },
                            model_paint_state.updown,
                            (updown) => update_model_paint({updown})
                        )
                    )
                }
                {
                    submenu(
                        emenu.splint_material, 
                        'select material',                    
                        EditMaterials(
                            model_paint_state.splint_type === esplint.gelb_split ? {
                                [ematerial.thermo]: 'soft_soft',
                                [ematerial.hard]: 'hard_hard',
                                [ematerial.dual]: 'hard_soft',
                            } : {
                                [ematerial.thermo]: 'soft_soft',
                                [ematerial.hard]: 'hard_hard',
                                [ematerial.dual]: 'hard_soft',
                                [ematerial.blue3d]: '3dprint_blue',
                                [ematerial.clear3d]: '3dprint_clear',
                            },
                            model_paint_state.splint_material,
                            (splint_material) => {
                                let patch: Partial<model_paint_state> = {splint_material};
                                // 이전 splint material이 3dprint이고, 새로운 splint material이 3dprint이 아니면, splint color를 clear로 변경
                                //
                                // if(is_3dprint(model_paint_state.splint_material) && !is_3dprint(splint_material)){
                                // if(!is_3dprint(splint_material)){
                                    // 
                                    patch = {
                                        ...patch, 
                                        splint_color: color_options.clear.mrc4
                                    };
                                // }
                                update_model_paint(patch);
                                update_decal_manage({decal: undefined})
                            },
                        )
                    )
                }
                {    
                    submenu(
                        emenu.splint_color, 
                        'select splint color',
                        EditColors(
                            [model_paint_state.splint_color], 
                            splint_colors => update_model_paint({splint_color: splint_colors[0]})
                        )
                    )
                }
                {    
                    submenu(
                        emenu.splint_attachments, 
                        'select attachments',
                        [
                            OptionsIcon(
                                {
                                    [eball_clasp.single]: 'side_one',
                                    [eball_clasp.double]: 'side_two',
                                    [eball_clasp.triple]: 'side_three',
                                },
                                eball_clasp.none,
                                model_paint_state.splint_ball_clasp,
                                (splint_ball_clasp) => update_model_paint({splint_ball_clasp}),
                            ),
                            model_paint_state.splint_type !== esplint.gelb_split &&
                            CheckIcon(
                                'point_04',
                                model_paint_state.splint_lingual_bar,
                                (splint_lingual_bar) => update_model_paint({splint_lingual_bar}),
                            ),
                        ],                    
                    )
                }
                </div>
            }
            {
                context.decals.filter(d => get_decal_renderable(d)).length > 0 &&
                <div 
                    style={{position: 'absolute', width: '10%', right: '25%'}}
                    key={'history-bar'}
                >                                    
                    <fieldset>
                        <legend>history</legend>
                        {
                            context.decals
                                .filter(d => get_decal_renderable(d))
                                .map((d, i) => 
                                    <button
                                        key={get_decal_key(d)}                                    
                                        onClick={() => {
                                            update_decal_edit({decal: edecal.none});
                                            update_decal_manage({decal: d});
                                        }}
                                    >
                                        <img 
                                            src={(d.tex as Texture).url!} 
                                            alt={d.tex.name.slice(0, 10)}
                                            style={{width: '40px', height: '40px'}}/>
                                    </button>
                                )
                        }
                    </fieldset>
                </div>
            }
            <div 
                style={{display: 'none'}}
                key={'hidden'}
            >
                <input
                    style={{display: 'none'}}
                    type="file"
                    accept="image/*"
                    ref={file_input}
                />
            </div>
        </div>
	);
};

function get_v3_key(v3: Vector3){
    return `${v3.x}:${v3.y}:${v3.z}`;
}

function get_decal_key(decal: decal){
    return `${decal.tex.name}:${get_v3_key(decal.ray.origin)}:${get_v3_key(decal.ray.direction)}:${decal.angle_rad}`;
}

function get_decal_renderable(decal: decal){
    return decal && decal.mesh && !decal.mesh.isDisposed();
}

export default Overlay;